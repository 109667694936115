import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';


/**
 * Material-UIを使った警告ダイアログのコンポーネント
 *
 * @param {Object} props - コンポーネントのプロパティ
 * @param {string} props.title - ダイアログのタイトル
 * @param {string} props.description - ダイアログの説明文
 * @param {boolean} props.open - ダイアログが開いているかどうかを制御するState
 * @param {Function} props.handleClose - ダイアログを閉じた時のコールバック関数
 * 
 * @returns {JSX.Element} AlertDialogコンポーネント
 */
export default function AlertDialog({title, description, open, handleClose}) {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {description}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} autoFocus>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
}