import ShortTextQuestion from '../components/ShortTextQuestion';
import RadioQuestion from '../components/RadioQuestion';
import CheckboxQuestion from '../components/CheckboxQuestion';
import DropdownQuestion from '../components/DropdownQuestion';
import LongTextQuestion from '../components/LongTextQuestion';
import FileUploadQuestion from '../components/FileUploadQuestion';
import ScaleQuestion from '../components/ScaleQuestion';
import DateQuestion from '../components/DateQuestion';


/** 
 * 質問のレンダリングを行う関数
 * @param {Object} item 質問のオブジェクト
 * @param {any} answer 質問の回答
 * @param {Function} setAnswer 回答を更新する関数
 * @param {number} index 質問のインデックス
 * @returns {JSX.Element} レンダリングされた質問要素
 */
const renderQuestion = (item, answer, setAnswer, index, displayValidationError) => {
    const questionType = getQuestionType(item.questionItem);
    switch (questionType) {
        // 未対応の質問タイプはnullを返す
        case 'OTHER':
            return null;
        case 'CHECKBOX':
            return <CheckboxQuestion item={item} answer={answer} setAnswer={setAnswer} index={index} displayValidationError={displayValidationError} />;
        case 'DROP_DOWN':
            return <DropdownQuestion item={item} answer={answer} setAnswer={setAnswer} index={index} displayValidationError={displayValidationError} />;
        case 'RADIO':
            return <RadioQuestion item={item} answer={answer} setAnswer={setAnswer} index={index} displayValidationError={displayValidationError} />;
        case 'SHORT_TEXT':
            return <ShortTextQuestion item={item} answer={answer} setAnswer={setAnswer} index={index} displayValidationError={displayValidationError} />;
        case 'LONG_TEXT':
            return <LongTextQuestion item={item} answer={answer} setAnswer={setAnswer} index={index} displayValidationError={displayValidationError} />;
        case 'FILE_UPLOAD':
            return <FileUploadQuestion item={item} answer={answer} setAnswer={setAnswer} index={index} displayValidationError={displayValidationError} />;
        case 'SCALE':
            return <ScaleQuestion item={item} answer={answer} setAnswer={setAnswer} index={index} displayValidationError={displayValidationError} />;
        case 'DATE':
            return <DateQuestion item={item} answer={answer} setAnswer={setAnswer} index={index} displayValidationError={displayValidationError} />;
        default:
            return <div>未対応の質問タイプが含まれています。</div>;
    }
}

/**
 * 与えられた質問項目から質問タイプを判断し、対応する文字列を返す関数
 * @param {Object} questionItem - 質問項目オブジェクト
 * @param {Object} questionItem.question - 質問オブジェクト
 * @param {Object} [questionItem.question.choiceQuestion] - 選択肢の質問オブジェクト
 * @param {Object} [questionItem.question.textQuestion] - テキストの質問オブジェクト
 * @param {Object} [questionItem.question.fileUploadQuestion] - ファイルアップロードの質問オブジェクト
 * @param {Object} [questionItem.question.scaleQuestion] - スケールの質問オブジェクト
 * @param {Object} [questionItem.question.dateQuestion] - 日付の質問オブジェクト
 * @returns {string} 質問タイプを表す文字列（'SHORT_TEXT', 'LONG_TEXT', 'FILE_UPLOAD', 'SCALE', 'DATE', 'UNKNOWN' のいずれか）
 */
const getQuestionType = (questionItem) => {
    const question = questionItem?.question;
    if (questionItem ==null) {
      return 'OTHER';
    }
    if (question?.choiceQuestion) {
      return question.choiceQuestion.type;
    } else if (question?.textQuestion) {
      return question.textQuestion.paragraph ? 'LONG_TEXT' : 'SHORT_TEXT';
    } else if (question?.fileUploadQuestion) {
      return 'FILE_UPLOAD';
    } else if (question?.scaleQuestion) {
      return 'SCALE';
    } else if (question?.dateQuestion) {
      return 'DATE';
    } else {
      return 'UNKNOWN';
    }
  }


export default renderQuestion;