import React, { useState } from 'react';
import { TextField, Box } from '@mui/material';
import { FormLabel } from '@mui/material';

/**
 * 短文のテキスト入力を行う質問のコンポーネント
 *
 * @param {Object} props - コンポーネントのプロパティ
 * @param {Object} props.item - 質問項目の情報
 * @param {number} props.index - 項目のインデックス
 * @param {Array} props.answer - 回答の配列
 * @param {boolean} props.displayValidationError - バリデーションエラーを表示するかどうか
 * 
 * @returns {JSX.Element} ShortTextQuestionコンポーネント
 */
function ShortTextQuestion(props) {
    const [inputValue, setInputValue] = useState('');
    const helperText = '必須項目です。';
    const index = props.answer.findIndex((item) => item.id === props.item.itemId);
    const obj = {
        "index": props?.index,
        "value": null,
        "label": props?.item?.title,
        "id": props?.item?.itemId
    }

    if (index === -1) {
        props.answer.push(obj);
    }

    const handleChange = (event) => {
        setInputValue(event.currentTarget.value);
        const index = props.answer.findIndex((item) => item.id === props.item.itemId);
        const newAnswerObject = {
            "index": props?.index,
            "value": event?.currentTarget?.value,
            "label": props?.item?.title,
            "id": props.item.itemId
        }
        if (index !== -1) {
            props.answer[index] = newAnswerObject;
        } else {
            props.answer.push(newAnswerObject);
        }
    };

    return (
        <div>
            <SingleLineTextInput
                title={props.item.title}
                description={props.item.description}
                handleChange={handleChange}
                value={inputValue}
                helperText={helperText}
                required={props.item?.questionItem?.question?.required}
                displayValidationError={props.displayValidationError}
            />
        </div>
    );
}

/**
 * 単一行テキスト入力フォームのコンポーネント
 *
 * @param {Object} props - コンポーネントのプロパティ
 * @param {string} props.title - フォームのタイトル
 * @param {string} [props.description] - フォームの説明文（省略可）
 * @param {Function} props.handleChange - テキスト入力が変更されたときの処理を行うコールバック関数
 * @param {string} props.value - 現在入力されている値
 * 
 * @returns {JSX.Element} SingleLineTextInputコンポーネント
 */
const SingleLineTextInput = ({ title, description, handleChange, value }) => {
  return (
    <Box>
      <FormLabel component="legend">{title}</FormLabel>
      <p className='description'>{description ? <p>{description}</p> : null}</p>
      <TextField
        sx={{
          marginBottom: "20px",
        }}
        variant="outlined"
        value={value}
        onChange={handleChange}
        fullWidth
      />
    </Box>
  );
};

export default ShortTextQuestion;