import React, { useState } from 'react';
import { FormControl, FormHelperText, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';


/**
 * ラジオボタンで選択を行う質問のコンポーネントです。
 *
 * @param {Object} props - コンポーネントのプロパティ
 * @param {Object} props.item - 質問項目の情報
 * @param {number} props.index - 項目のインデックス
 * @param {Array} props.answer - 回答の配列
 * @param {boolean} props.displayValidationError - バリデーションエラーを表示するかどうか
 * 
 * @returns {JSX.Element} RadioQuestionコンポーネント
 */
function RadioQuestion(props) {
    const [selectedValue, setSelectedValue] = useState('');
    const errMsg = props.item?.questionItem?.question?.required ? '必須項目です。' : '';

    const [helperText, setHelperText] = useState(errMsg);

    const index = props.answer.findIndex((item) => item.id === props.item.itemId);
    const obj = {
        "index": props?.index,
        "value": null,
        "label": props?.item?.title,
        "id": props?.item?.itemId
    }

    if (index === -1) {
        props.answer.push(obj);
    }

    const handleChange = (event) => {
        setSelectedValue(event.currentTarget.value);
        const index = props.answer.findIndex((item) => item.id === props.item.itemId);
        const newAnswerObject = {
            "index": props?.index,
            "value": event?.currentTarget?.value,
            "label": props?.item?.title,
            "id": props.item.itemId
        }
        if (index !== -1) {
            props.answer[index] = newAnswerObject;
        } else {
            props.answer.push(newAnswerObject);
        }
        if (event.currentTarget.value === '') {
            setHelperText('必須項目です。');
        } else {
            setHelperText('');
        }
    };


    const radioOptions = props.item.questionItem.question.choiceQuestion.options;
    return <div>
        <RadioInputForm
            title={props.item?.title}
            description={props.item?.description}
            options={radioOptions}
            handleChange={handleChange}
            helperText={helperText}
            value={selectedValue}
            required={props.item?.questionItem?.question?.required}
            displayValidationError={props.displayValidationError}
        />
    </div>
}


/**
 * ラジオボタン入力フォームのコンポーネント
 *
 * @param {Object} props - コンポーネントのプロパティ
 * @param {string} props.title - フォームのタイトル
 * @param {string} [props.description] - フォームの説明文（任意）
 * @param {Array} props.options - ラジオボタンの選択肢
 * @param {Function} props.handleChange - 選択肢が変更されたときの処理を行うコールバック関数
 * @param {string} props.helperText - ヘルパーテキスト（例：エラーメッセージなど）（displayValidationError=trueの時は不要）
 * @param {string} props.value - 現在選択されている値
 * @param {boolean} props.required - フォームが必須項目かどうか
 * @param {boolean} props.displayValidationError - バリデーションエラーを表示するかどうか
 * 
 * @returns {JSX.Element} RadioInputFormコンポーネント
 */
const RadioInputForm = ({ title, description, options, handleChange, helperText, value, required, displayValidationError }) => {
    return (
      <FormControl component="fieldset"
        sx={{
            '& .MuiFormControlLabel-root': {
              height: "30px",
            }
          }}>
        <FormLabel component="legend" required={required}
          sx={{
            fontSize: "1rem",
            marginBottom: "8px",
          }}>{title}</FormLabel>
        {description ? <p>{description}</p> : null}
        <RadioGroup
          sx={{
            marginBottom: "12px",
          }}
          aria-label={title}
          name={title}
          value={value}
          onChange={handleChange}
        >
          {options.map((option, index) => (
            <FormControlLabel
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontSize: "0.85rem",
                  color: "rgba(0, 0, 0, 0.6)",
                },
              }}
              key={index}
              value={option.value}
              control={<Radio
                sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: 20,
                  },
                }} />}
              label={option.value}
            />
          ))}
        </RadioGroup>
        {displayValidationError ? <FormHelperText error={true} >{helperText}</FormHelperText>: null}
      </FormControl>
    );
  };
  

export default RadioQuestion;