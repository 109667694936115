import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Logo from '../iyell-logo_2023A.jpg';
import renderQuestion from '../functions/renderQuestion';
import { Button } from '@mui/material';
import AlertDialog from '../components/AlertDialog';

// Homeコンポーネント
function Home() {
  return (
    <div className='App'>
      <img className='App-logo' src={Logo} alt='画像の説明文' />
      <Form></Form>
    </div>
  );
}

// フォームコンポーネント
const Form = () => {
  const [title, setTitle] = useState([]);
  const [description, setDescription] = useState([]);
  const [form, setForm] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [answer, setAnswer] = useState([]);
  const [displayValidationError, setDisplayValidationError] = useState(false);

  // Google Formから入力項目を取得するAPIのURL
  const API_URL = 'https://eauh8nciqd.execute-api.ap-northeast-1.amazonaws.com/prod/google-forms'; // ★TODO：環境に合わせて変更する
  // Google Spread Sheetへ入力項目を登録するAPIのURL
  const SHEET_URL = 'https://eauh8nciqd.execute-api.ap-northeast-1.amazonaws.com/prod/google-spreadsheet'; // ★TODO：環境に合わせて変更する

  /**
   * バリデーションエラーのダイアログで
   * Closeを押した時、ダイアログを閉じる処理をする関数。
   *
   * @returns {void}
   */
  const onClose = () => {
    setOpen(false);
  };

  /**
   * フォームの送信処理を行う関数。
   * @param {Array} answers - フォームの入力値を格納したオブジェクトの配列。
   * @returns {void}
   */
  const onSubmit = (answers) => {
    if (!(answers.filter((item) => item.value === null).length === 0)) {
      // 未入力の項目がある場合はエラーを表示する
      setOpen(true);
      setDisplayValidationError(true);
    } else {
      const answer = {
        answers: answers,
      };
      // Spreadsheetに送信する処理
      axios
        .post(SHEET_URL, answer, { headers: {} })
        .then(() => {
          setIsLoading(false);
          setIsSubmitted(true);
        })
        .catch((error) => {
          setError(error);
          setIsLoading(false);
        });
    }
  };

  // フォームの初期値を取得するフック
  useEffect(() => {
    axios({
      method: 'get',
      headers: {},
      url: API_URL,
    })
      .then((response) => {
        const obj = JSON.parse(response.data.body);
        setIsLoading(false);
        setForm(obj?.items);
        setTitle(obj?.info?.title);
        setDescription(obj?.info?.description);
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    // フォーム読み込み中の画面
    return <div>Loading...</div>;
  } else if (error) {
    // フォーム読み込み失敗時の画面
    return <div>Error: {error.message}</div>;
  } else if (isSubmitted) {
    // 回答送信後の画面
    return <div>ご回答いただきありがとうございました。</div>;
  } else {
    // フォームを表示するメインの画面
    return (
      <div className='Content'>
        <AlertDialog title='確認' description='未入力の必須項目があります。ご確認ください。,' open={open} handleClose={onClose} />
        <header className='App-header'>
          <text className='title'>{title}</text>
          <div className='description'>{description}</div>
        </header>
        <div className='App-content'>
          <div>
            {form.map((item, index) => (
              <div key={index} className='question'>
                {renderQuestion(item, answer, setAnswer, index, displayValidationError)}
              </div>
            ))}
          </div>
          <br />
          <Button variant='contained' onClick={() => onSubmit(answer)}>
            送信
          </Button>
        </div>
      </div>
    );
  }
};

export default Home;
